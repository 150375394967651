<template>
  <layout-template>
    <template slot="main">
      <list-template>
        <template v-slot:header>
          <div class="flex flex-between-center container header">
            <div>
              <div class="flex flex-cross-center">
                <div class="nickname">{{floor|placeTypeFormator}}</div>
                <div class="select" @click.stop="showSelect=true" v-if="floor&&floor.level&&floor.level!==3">
                  <span>{{room|placeTypeFormator}}</span>
                  <img src="@/assets/arrow_down.svg" class="icon"/>
                </div>
              </div>
              <div class="department">{{buildingName}}</div>
            </div>
            <div class="logout flex flex-center" @click="bathControl">批量操作</div>
          </div>
        </template>
        <group-checkbox :options="options" v-model="queryType"></group-checkbox>
        <div class="scroll-container">
          <base-scroll ref="deviceList" load-data-url="/h5/place/mapRoom/page" :query="{path:room.id ? room.path + '-' + room.id : floor.path + '-' + floor.id, deviceStatus: queryType}"  @choose="onCheck" @total="onGetTotal">
            <template v-slot="{rows}">
              <div v-for="(row,index) of rows" :key="row.id" class="device relative" :data-index="index">
                <div class="alias">{{row.deviceName? row.deviceName: row.sn}}</div>
                <div class="address">{{row.address}}</div>
                <div class="flex status-bar">
                  <img v-if="row.status==='on'" src="@/assets/power_on.svg" class="icon" data-action="power"/>
                  <img v-else src="@/assets/power_off.svg" class="icon" data-action="power"/>
                  <img src="@/assets/setting.svg" class="icon" data-action="setting"/>
                  <div class="status-wrapper flex-auto flex flex-cross-center flex-main-around" :class="statusMap[row.status]">
                    <template v-if="row.status==='exception'">
                      <span>--</span>
                      <span>异常</span>
                      <span>--</span>
                    </template>
                    <template v-else-if="row.connection==='offline'">
                      <span>--</span>
                      <span>离线</span>
                      <span>--</span>
                    </template>
                    <template v-else>
                      <span>{{row.status==='off'? '--':row.mode}}</span>
                      <span>{{row.status==='off'? '--':row.rank}}</span>
                      <span>{{row.status==='off'? '--':row.temperature|| '--'}}℃</span>
                    </template>
                  </div>
                </div>
                <div class="detail flex flex-center" data-action="detail">详情</div>
                <div v-if="row.status==='repair'" class="detail flex flex-center" style="right:54px;color:#FF5959; background:rgba(255,0,0,0.06);">报修</div>
              </div>
              <div style="height:120px"></div>
            </template>
          </base-scroll>
        </div>
        <template v-slot:footer>
          <transition name="slide-up">
            <div v-show="showSelect" class="select-bar" @click.stop.prevent @dblclick.stop.prevent>
              <div class="select-header flex flex-between-center">
                <div class="btn" @click="cancelSelect">取消</div>
                <div>选择房间</div>
                <div class="btn" @click="onConfirm">确定</div>
              </div>
              <div class="select-content">
                <base-scroll method="get" :load-data-url="`/h5/place/getChildrenWithoutChildrenAndParent/${floor.id}`" mode="native" type='room' :query="{id:floor.id}" @choose="onRoomTap">
                  <template v-slot="{rows}">
                    <div class="room" v-for="(row,index) in rows" :data-index="index" :key="row.id">
                      <span>{{row|placeTypeFormator}}</span>
                      <img src="@/assets/yes.svg" class="icon" v-if="currentSelect?currentSelect.id===row.id:selectedRoom.id===row.id"/>
                    </div>
                  </template>
                </base-scroll>
              </div>
            </div>
          </transition>
        </template>
        <!-- <base-confirm :show.sync="showConfirm" title="操作提示">开关机指令发送成功，请稍后刷新列表状态</base-confirm> -->
      </list-template>
    </template>
  </layout-template>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BaseScroll from '@/components/base-scroll'
  import GroupCheckbox from '@/components/group-checkbox'
  // import BaseConfirm from '../components/base-confirm'
  export default {
    name: 'Home',
    components: { BaseScroll, GroupCheckbox },
    data () {
      return {
        buildingName: localStorage.getItem('name'),
        remoteOpenCommands: [
          { code: 'SWITCH', name: '开机', value: 1 },
          { code: 'AUTO', name: '全自动', value: 1 }
        ],
        templateCommands: [],
        remoteCloseCommands: [{ code: 'SWITCH', name: '关机', value: 0 }],
        showConfirm: false,
        statusMap: {
          open: 'color-primary',
          repair: 'color-danger',
          exception: 'color-danger'
        },
        rooms: [],
        selectedRoom: {},
        currentSelect: null,
        showSelect: false,
        queryType: 1,
        options: [
          {
            name: '全部',
            value: 1,
            filter: x => x
          },
          {
            name: '开机',
            value: 2,
            filter: x => x.status === 'on'
          },
          {
            name: '关机',
            value: 3,
            filter: x => x.status === 'off'
          },
          {
            name: '在线',
            value: 4,
            filter: x => x.connection === 'online'
          },
          {
            name: '异常',
            value: 5,
            filter: x => x.running === 'exception'
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['building', 'block', 'floor', 'room'])
    },
    filters: {
      placeTypeFormator: function (row) {
        if (row && row.name) {
          var suffix = /^[0-9]*$/.test(row.name) ? ['', '单元', '楼层', '号'][row.level] : ''
          return row.name + suffix
        } else {
          return '选择房间'
        }
      }
    },
    methods: {
      async onCheck (device, dom) {
        const action = dom.getAttribute('data-action')
        console.log('device', device)
        switch (action) {
          case 'power':
            if (device.connection !== 'online') {
              this.$toast.show('设备当前不可控制')
              break
            }

            if (device.status !== 'on' && device.status !== 'off') {
                 this.$toast.show('当前设备异常或出于报修中，请进入详情控制操作')
              break
            }


            if (device.status !== 'on') {
              try {
                const res = await this.$axios.post('/h5/device/getCurrentTemplate')
                if (res && res.value) {
                  const value = JSON.parse(res.value)
                  this.remoteOpenCommands = value.commands
                } else {
                  this.remoteOpenCommands = [
                    { code: 'SWITCH', name: '开机', value: 1 },
                    { code: 'AUTO', name: '全自动', value: 1 }
                  ]
                }
              } catch (error) {
                this.remoteOpenCommands = [
                    { code: 'SWITCH', name: '开机', value: 1 },
                    { code: 'AUTO', name: '全自动', value: 1 }
                ]
                // console.error('获取当前模板失败')
              }
            }
            this.$axios.post(`/h5/device/exec/${device.deviceId}`, device.status !== 'on' ? this.remoteOpenCommands : this.remoteCloseCommands).then(res => {
              if (res) {
                // device.status = 'off'
                // this.showConfirm = true
                this.$toast.show('指令下发成功，请稍后刷新列表状态')
              } else {
                this.$toast.show('指令下发失败')
              }
            }).catch(() => {
              this.$toast.show('指令下发失败')
            })
            break
          case 'setting':
            if (device.connection === 'online') {
              this.$router.push({ name: 'deviceControl', params: { id: device.deviceId, sn: device.sn } })
            } else {
              this.$toast.show('设备当前不可控制')
            }
            break
          case 'detail':
            this.$router.push({ name: 'deviceDetail', params: { id: device.deviceId, device: device } })
            break
          default:
        }
      },
      onGetTotal (total) {
        this.total = total
      },
      bathControl () {
        if (this.queryType === 5) {
          this.$toast.show('异常设备不可批量控制')
        } else {
          this.$router.push({
            name: 'deviceBath',
            query: {
              queryType: this.queryType,
              total: this.total
            }
          })
        }
      },
      onConfirm () {
        if (this.currentSelect) {
          if (this.currentSelect.id === 'room') {
            this.selectedRoom = {}
            this.currentSelect = null
            this.$store.commit('SET_ROOM', {})
            this.showSelect = false
            return
          }
          this.selectedRoom = this.currentSelect
        }
        this.$store.commit('SET_ROOM', this.selectedRoom)
        this.showSelect = false
        this.currentSelect = null
      },
      onRoomTap (room) {
        this.currentSelect = room
        // this.selectedRoom = room
      },
      cancelSelect () {
        this.showSelect = false
        this.currentSelect = null
      }
    },
    mounted () {
      document.addEventListener('click', () => {
        this.showSelect = false
        this.currentSelect = null
      })
    }
  }
</script>
<style scoped lang="stylus">
  .header
    padding 0 32rpx
  .nickname
    font(32,48,#fff)
    margin-bottom 8rpx
  .department
    font(24,40,rgba(255,255,255,.65))
    ellipse(500)
  .logout
    width:128rpx;
    height:48rpx;
    background:rgba(255,255,255,0.35);
    border-radius:24rpx;
    font(24,40,#fff)
  .select
    background:rgb(166,164,196);
    border-radius:24rpx;
    padding: 0 20rpx
    font(28,48,#fff)
    margin-left 20rpx
  .select-bar
    position fixed
    bottom 0
    left 0
    right 0
    .select-header
      font(32,80,$text)
      background #ebebf0
      padding 0 32rpx
      .btn
        color #1979ff
  .select-content
    height 600rpx
    background #fff
    .room
      height 96rpx
      position relative
      text-align: center
      border-bottom 1rpx solid rgba(31,31,52,0.3);
      font(28,96,$text)
      .icon
        position absolute
        top 24rpx
        right 32rpx
  .group-checkbox
    background rgba(255,255,255,0.1) !important;
    color rgba(255,255,255,.65) !important
  .scroll-container
    height calc(100% - 152rpx)
  .device
    background #fff
    border-radius 20rpx
    padding 28rpx 32rpx
    margin 40rpx 32rpx
    font(28, 44, $subtext)
    &:first-child
      margin-top 0
    .alias
      font(32,48,$text)
      ellipse(500)
    .status-bar
      margin-top 20rpx
      .icon
        box(72,72)
        margin-right 20rpx
        pointer-events: auto; /* 禁止长按保存 */
      .status-wrapper
        margin-left 20rpx
        background rgba(31,31,52,0.05)
        border-radius 20rpx
        &.color-primary
          background rgba(25,121,255,0.08);
          color #1979FF
        &.color-danger
          background rgba(255,0,0,0.06);
          color #FF5959
  .detail
    width:80rpx;
    height:48rpx;
    background:rgba(25,121,255,0.08);
    border-radius:24rpx;
    font(24,40,#1979FF)
    position absolute
    top 28rpx
    right 32rpx
  .address
    ellipse(500)
  .group-checkbox
    margin 40rpx 32rpx !important
</style>
